@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/swiper/swiper-bundle.css";
@import "../node_modules/primeng/resources/primeng.min.css";
//@import "../src/assets/params/css/tailwind-light/theme-artas.scss";
@import "../src/assets/params/css/tailwind-light/theme-art-club.scss";
@import "../node_modules/primeicons/primeicons.css";

@font-face {
  font-family: "peace_sans";
  src: url('assets/params/fonts/peace_sans.otf') format("truetype");
}
#videoAR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:-1!important;

}
.image-container {
  display: flex;
  overflow-x: auto; /* Добавляем горизонтальную прокрутку */
  scrollbar-width: none; /* Скрываем стандартные полосы прокрутки */
  -ms-overflow-style: none; /* IE и Edge */
}
.hidden-text {
  visibility: hidden;
}

.custom-image-cropper .ngx-ic-square {
  display: inline-block;
  background: #fd8700 !important;
  width: 18px !important;
  height: 16px !important;
  border: 2px solid rgb(171, 93, 0) !important; /* Более выразительная граница */
  border-radius: 50%; /* Закругленные углы */
  box-sizing: content-box !important;
  transition: all 0.3s ease-in-out; /* Анимация */
}

.custom-image-cropper .ngx-ic-square:hover {
  transform: scale(1.2); /* Увеличение размера при наведении */
  background: #eea654 !important; /* Изменение цвета при наведении */
  border: 2px solid rgb(241, 187, 122); /* Изменение цвета границы при наведении */
}
/*[_nghost-ng-c1583925566] .ngx-ic-cropper[_ngcontent-ng-c1583925566] .ngx-ic-resize[_ngcontent-ng-c1583925566] .ngx-ic-square[_ngcontent-ng-c1583925566] {
  display: inline-block;
  background: #020247!important;
  width: 18px!important;;
  height: 16px!important;;
  border: 1px solid rgba(255, 255, 255, .5)!important;;
  box-sizing: content-box!important;;
}*/


/* Стиль для полосы прокрутки в Firefox */
.image-container::-webkit-scrollbar {
  display: none;
}
.scale02 {
  //transform: scale(0.2)!important;
  zoom: 85%!important;
  z-index: 100000!important;

}
#overlayAR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:10000!important;
  pointer-events: none; /* Позволяет кликать сквозь наложенное изображение */
}


/*@font-face {
  font-family: "lobster";
  src: url('assets/params/fonts/Lobster-Regular.ttf') format("truetype");
}*/
[_nghost-serverApp-c209] .cropper[_ngcontent-serverApp-c209] .resize[_ngcontent-serverApp-c209]
{
  background: #000!important;
}
body {
  margin: 0;
  padding: 0;
  //overflow: hidden;
  //background-color: #f0f0f0;
  height: 100%;
  //overflow: auto;
}
.joystick {
  height: 200px;
  width: 200px;
  display: inline-block;
  border: 3px solid #ff0303;
  position: relative;
  border-radius: 100%;
  display: inline-block;
}

.joystick span.stick {
  position: absolute;
  height: 30%;
  width: 30%;
  background: #ff0303;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  pointer-events: none !important;
}
#large-container {
  width: 1000px;
  height: 1000px;
  overflow: hidden;
}
.click-animation{
  @apply focus:ring-4 shadow-lg transform active:scale-150 transition-transform;
}

.move-element-stage{
position:absolute;
  width: 1000px;
  height: 1000px;
  background: #fd0000;
}
#scroll-container {
  //width: calc(100% - 22px);
  //height: calc(100vh - 22px);
  overflow: hidden;
  //margin: 10px;
  border: 1px solid grey;
}
/*.render-zone {
  transform: scale(1.4);
}*/
.float-card {
  //border: 3px dashed rgba(0, 0, 0, 0.29);
  //box-shadow: 10px 5px 5px rgb(0 0 0 / 29%);
  //position: absolute;
  //top: -300px;
  display: block;

  //left: 100%;
  z-index: 100000 !important;
  //background-color: #373839;
  border-radius: 0.75em;
  position: absolute;

}

.swiper {
  width: 600px;
  height: 300px;
}

/*#drag-1, #drag-2 {
  width: 25%;
  min-height: 6.5em;
  margin: 1rem 0 0 1rem;
  background-color: #29e;
  color: white;
  border-radius: 0.75em;
  padding: 4%;
  touch-action: none;
  user-select: none;
  transform: translate(0px, 0px);
}*/
.navbar-interact {
  border: 3px dashed rgba(0, 0, 0, 0.29);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.29);
  //position: absolute;
  //top: 50%;
  //right: 0;
  //z-index: 100000 !important;
  //width: 200px;
  min-height: 50px;
  min-width: 50px;
  background-color: #373839;
  border-radius: 0.75em;
}



.close-icon {
  @apply absolute rounded-md  w-[100px] h-[150px] -top-[450px] -left-[100px] text-center text-sm text-zinc-200 bg-zinc-800;
}

.ref-swipe-left-cards {
  @apply absolute rounded-md  w-[100px] h-[250px] top-[6%]  left-[5%] ;
}

.iro-colors {
  @apply absolute rounded-md  w-[100px] h-[150px] bottom-[220px]  left-[5%] ;
}

.ink-swipe-left-cards {
  //@apply absolute rounded-md  w-[100px] h-[250px] top-[9%]  right-[5%] z-20 ;
}

.saved-swipe-left-cards {
  @apply absolute rounded-md  w-[100px] h-[150px] top-[50%]  right-[5%]   ;
}

.favorites-colors {
  @apply z-10 block border-8 rounded-full animate-pulse border-zinc-800 absolute left-[5%] bottom-[1%] w-[80px] h-[80px] p-1 m-1;
}

.size-round {
  @apply z-10 block animate-pulse border-zinc-800 absolute right-[5%] bottom-[1%] w-[80px] h-[80px] p-1 m-1;
}

.swipe-left-card {
  @apply block w-[120px] h-[150px];
}

.swipe-right-cards {
  @apply absolute rounded-md  w-[100px] h-[150px] -top-[550px] right-[10px];
}

.favorites-items {
  @apply absolute rounded-md  left-[89px];
}

.swipe-right-card {
  @apply block w-[120px] h-[150px] rounded-sm bg-zinc-800;
}

.favorites-count {
  @apply absolute rounded-md opacity-75 w-[20px] h-[20px] -top-[20px] -left-[20px] text-center text-sm text-zinc-200 bg-zinc-800;
}

.uploaded-count {
  @apply absolute rounded-md opacity-75 w-[20px] h-[20px] top-[10px] right-[10px] text-center text-sm text-zinc-200 bg-zinc-800;
}

.brush-eraser {
  @apply block animate-pulse absolute left-[35px] -top-[100px] w-[60px] h-[60px];
}

.round-stroke {
  @apply block animate-pulse absolute left-[15px] -top-[60px] w-[40px] h-[40px];
}


.size-knob {
  @apply absolute right-[5px] -top-[5px];
}

.brushes-size-presets {
  left: -19px;
  top: -7px;
  @apply absolute;
}

.plus-panel {
  @apply navbar shadow-md shadow-slate-500;
}

.cursor-image {
  pointer-events: none
}

.lock-click-element {
  pointer-events: none
}

$navbar-height: 70px;
$circle-radius: 50px;
$backgroundColor: rgb(253, 192, 223);

.tool-right-float {
  //display: inline-flex;
  height: auto;
  //border: 3px dashed rgba(0, 0, 0, 0.29);
  //box-shadow: 10px 5px 5px rgb(0 0 0 / 29%);
  position: fixed;
  //right: 5%;
  //z-index: 50000 !important;
  min-height: 50px;
  min-width: 50px;
  //border-radius: 0.75em;
}

.konvajs-content {
  z-index: 0 !important;
}
#scale-element {
  display: block;
  max-width: 100%;
  margin: 1rem auto;
  touch-action: none;
}

#scale-element.reset {
  transition: transform 0.3s ease-in-out;
}
.render-zone {
  @apply border-dashed border-2 border-gray-700;
  //position:fixed;
  //height: 500px;
  //width: 500px;
  //cursor:move;
  //transform:translate(-50%,-50%);
  //text-align: center;
  //display: inline-block;
  //background-repeat: no-repeat;
  //background-size: cover;

}

canvas {
  //position:relative!important;
  //display: block!important;
  z-index: 0 !important;
}

.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: $backgroundColor;
}

*::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
  background: #f1921f !important;
}

.vertical-position {
  @apply w-[150px] h-[400px];
}

.horizontal-position {
  @apply w-[400px] ;
}

.grid-position {
  @apply w-[400px] h-[400px];
}

.vertical-position-item {
  @apply w-[50px] h-[40px];
}

.horizontal-position-item {
  @apply w-[400px] h-[150px];
}

.grid-position-item {
  @apply w-[50px] h-[50px];
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px !important;;
  background: #954b00 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px !important;;
  background: #924800 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;;
}

.navbar {
  position: relative;
  height: $navbar-height;
  min-width: 400px;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  //background-color: white;
  //box-shadow: 0 10px 20px rgba(82, 74, 74, 0.3);
}


.item-scroll {
  display: inline-block;
  min-width: 10px;
  //margin: 0.2em 0.1em;
  margin: 0;
  //box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.scroll-items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;

}

.scroll-items.active {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.navbar:after {
  content: "";
  position: absolute;
  bottom: 7px;
  height: 4px;
  width: 35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: gray;
  opacity: 0.3;
}

.circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
  margin-bottom: 0;
  height: $circle-radius;
  width: $circle-radius;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //background-color: rgb(255, 94, 0);
  z-index: 9;
  //box-shadow: 0 5px 5px rgba(255, 147, 85, 0.35);
  transition: height 0.3s;
}

.circle:hover {
  height: 200px;
  border-radius: 50px;
}

.circle .plus-icon {
  color: white;
  font-size: 27px;
  transition: opacity 0.3s, transform 0.2s;
}
.btn-controll {
 @apply click-animation p-1 bg-zinc-700  text-zinc-200;
}
.btn-controll-image {
 @apply click-animation flex  min-h-[20px] w-[10] flex-col items-center justify-center gap-1 rounded-md p-[2px] bg-zinc-700 text-amber-600;
}
.grid-bar-3 {
 @apply bg-zinc-600 p-1 m-1 grid grid-cols-3 gap-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3;
}
.grid-bar-5 {
 @apply bg-zinc-600 p-1 m-1 grid grid-cols-5 gap-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5;
}

.header-image {
 @apply relative overflow-hidden rounded-lg shadow-lg cursor-pointer;
}

.circle:hover .plus-icon {
  transform: rotate(360deg);
  opacity: 0;
}

.circle .social {
  position: absolute;
  top: 195px;
  //color: white;
  //background-color: rgba(255, 212, 212, 0.308);
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.circle .social:nth-child(2) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s ease;
}

.circle .social:nth-child(3) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s 0.1s ease;
}

.circle .social:nth-child(4) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s 0.22s ease;
  margin-bottom: 0px;
}

.circle:hover .social:nth-child(2) {
  opacity: 1;
  top: 5px;
}

.circle:hover .social:nth-child(3) {
  opacity: 1;
  top: calc(5px + 60px + 5px);
}

.circle:hover .social:nth-child(4) {
  opacity: 1;
  top: calc(5px + 60px + 5px + 60px + 5px);
}

.circleBackground {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(70px - #{$circle-radius + 20} / 2);
  margin-bottom: 0;
  height: calc(#{$circle-radius} + 20px);
  width: calc(#{$circle-radius} + 20px);
  border-radius: calc(#{$circle-radius + 20} / 2);
  //background-color: $backgroundColor;
}

.icon-home,
.icon-settings {
  font-size: 29px;


  //color: $backgroundColor;
}

.icon-center-tools {
  font-size: 15px;
  margin: 2px !important;
  padding: 2px;
  border-radius: calc(#{$circle-radius + 20} / 2);

  //color: $backgroundColor;
}


/*
.p-tabview-nav-link {
  //@extend .tab;
}

*/



.btn-move-drag {
 /* line-height: 1.35rem !important;
  margin: 0.05rem !important;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  border-radius: 0.55rem;
  border-width: 1px;
  z-index: 10;
  position: absolute;
  //right: -16.3px;
  //top: -24.6px;
  @apply absolute bg-zinc-800 text-zinc-200   ;
  @apply shadow-zinc-600 shadow-lg;*/
}

.tag {
  @apply bg-zinc-800 text-zinc-200;
  @apply shadow-zinc-600 shadow-lg;
  @apply px-2   m-3;
  @apply border rounded;
  @apply truncate;
}

/*
.tab {
  @apply bg-zinc-100 text-zinc-600;
  @apply shadow-zinc-300 shadow-lg;
  @apply px-2   m-3;
  @apply border rounded;
  @apply truncate;
}
*/

.widgetLabel {
  position: absolute;
  height: 42px;
  bottom: 61px;
  z-index: 1;
  white-space: nowrap;
  font-size: 15px;
  line-height: 17px;
  border-radius: 16px;
  color: antiquewhite !important;
  padding: 10px 15px;
  box-shadow: rgb(106 13 49) 0px 6px 32px 0px;
  background: rgb(74 6 32) !important;
}

.row-scroll {
  @apply flex justify-center;
}

.name-block {
  @apply p-3 absolute top-[350px] right-[10px] bg-zinc-500/80 text-zinc-300 border-gray-300/20  border-4 rounded-md ;
}

.cat-block {
  @apply p-3 absolute top-[10px] left-[20px]  bg-zinc-500/80 text-zinc-300 border-gray-300/20  border-4 rounded-md ;
}

.price-block {
  @apply p-3 absolute top-[70px] left-[20px] bg-zinc-500/80 text-zinc-300 border-gray-300/20  border-4 rounded-md ;
}

.name-block {
  @apply bg-yellow-200/20 border border-gray-700 rounded-md text-zinc-300 py-1 mb-1 ;
  @apply w-[400px];
  @apply h-[50px];
}

.desc-block {
 @apply   bg-yellow-200 p-1 m-1 italic font-bold  text-lg text-[#2f0414] rounded-tl-lg rounded-br-lg;
  //@apply text-sm bg-zinc-700/20 p-3 border border-gray-700 rounded-md text-zinc-300 p-1 m-1;
}

.text-block {
  @apply text-lg  bg-zinc-700/20 p-3 border border-gray-700 rounded-md text-pink-100 p-1 m-1 italic;
}

.scroll-items {
  @apply  sm:overflow-auto md:overflow-hidden w-full  lg:overflow-hidden w-full;
}


.src-preview {
  /* border-4 rounded-full*/
  @apply shadow-lg w-[400px] h-[400px] ;
}


.card-round {
  //transform: scale(0.8);
  //@apply w-[400px] h-[400px] mb-2  ;
}

.tabs {
  @apply mx-auto  ;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .card {
    flex: 0 0 auto;
  }
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  //@apply bg-zinc-600 text-zinc-100;
  //background: #000!important;
}


/*.swiper-scroll-row {
  width: 400px;
  height: 100px;
}*/
/*.swiper-counter{

}*/

/*
.swiper-button-next, .swiper-button-prev, {
  color: #f1921f !important;
}
*/

/*.swiper-scrollbar-drag::before {
  align-content: center;
  font-weight: bold;
  color: #313131;
  top: 50px;
  content: "<||||||>" !important;
}*/

/*.swiper-scrollbar-drag, .swiper-pagination-bullet-active {
  height: 50px !important;
  background: #f1921f !important;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  left: 0;
  top: -15px;
}*/

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


:root {
  --sp: 2.5s; /* change speed */
}
.wrapper-preload{
  width:200px;
  height:60px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
}
.circle-preload{
  width:20px;
  height:20px;
  position: absolute;
  border-radius: 50%;
  background-color: #ec8d19;
  left:15%;
  transform-origin: 50%;
  animation: circle-preload .5s alternate infinite ease;
}

@keyframes circle-preload{
  0%{
    top:60px;
    height:5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40%{
    height:20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100%{
    top:0%;
  }
}
.circle-preload:nth-child(2){
  left:45%;
  animation-delay: .2s;
}
.circle-preload:nth-child(3){
  left:auto;
  right:15%;
  animation-delay: .3s;
}
.shadow-preload{
  width:20px;
  height:4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  position: absolute;
  top:62px;
  transform-origin: 50%;
  z-index: -1;
  left:15%;
  filter: blur(1px);
  animation: shadow-preload .5s alternate infinite ease;
}

@keyframes shadow-preload{
  0%{
    transform: scaleX(1.5);
  }
  40%{
    transform: scaleX(1);
    opacity: .7;
  }
  100%{
    transform: scaleX(.2);
    opacity: .4;
  }
}
.shadow-preload:nth-child(4){
  left: 45%;
  animation-delay: .2s
}
.shadow-preload:nth-child(5){
  left:auto;
  right:15%;
  animation-delay: .3s;
}
.wrapper-preload span{
  position: absolute;
  top:75px;
  font-size: 20px;
  letter-spacing: 12px;
  color: #ec8d19;
  left:15%;
}






.content {
  width: 50vmin;
  height: 50vmin;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.65);
  animation: spin-all calc(var(--sp) * 2) linear 0s infinite
}

.circle {
  --in: 80%;
  --ar: #ff9800;
  --dt: #ffea34;
  --shadow: drop-shadow(0vmin 0vmin 0.5vmin #000) drop-shadow(0vmin 1vmin 0.5vmin #0004);
  --cross: linear-gradient(0deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px)), linear-gradient(90deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px));
  border: 6vmin solid var(--ar);
  width: var(--in);
  height: var(--in);
  border-radius: 100%;
  position: absolute;
  box-sizing: border-box;
  border-top-color: #fff0;
  border-left-color: #fff0;
  top: 15vmin;
  right: -10vmin;
  animation: spin-bot var(--sp) ease 0s infinite;
  background-image: var(--cross), radial-gradient(var(--dt) 5.5vmin, #fff0 calc(5.5vmin + 1px));
  background-repeat: no-repeat;
  background-size: 3vmin 1vmin, 1vmin 3vmin, 100% 100%;
  background-position: center center;
  filter: var(--shadow);
}

.circle:nth-child(2) {
  --in: 60%;
  top: -2vmin;
  animation: spin-top var(--sp) ease 0s infinite;
  transform: rotate(-45deg);
  background-image: var(--cross), radial-gradient(var(--dt) 1.25vmin, #fff0 calc(1.25vmin + 1px));
  right: -4vmin;
  filter: hue-rotate(10deg) var(--shadow);
  background-size: 1.4vmin 1vmin, 1vmin 1.4vmin, 100% 100%;
}

.circle:nth-child(3) {
  --in: 100%;
  top: -5vmin;
  left: -13vmin;
  transform: rotate(175deg);
  animation: spin-left var(--sp) ease calc(var(--sp) / 4) infinite;
  background-image: var(--cross), radial-gradient(var(--dt) 9vmin, #fff0 calc(9vmin + 1px));
  filter: hue-rotate(20deg) var(--shadow);
  background-size: 5vmin 1vmin, 1vmin 5vmin, 100% 100%;
}

.circle:nth-child(4) {
  --in: 60%;
  top: 35vmin;
  left: -6vmin;
  transform: rotate(-280deg);
  animation: spin-last var(--sp) ease calc(calc(calc(var(--sp) / 4) + var(--sp)) * -1) infinite;
  background-image: var(--cross), radial-gradient(var(--dt) 2.5vmin, #fff0 calc(2.5vmin + 1px));
  filter: hue-rotate(30deg) var(--shadow);
  background-size: 2vmin 1vmin, 1vmin 2vmin, 100% 100%;
}


@keyframes spin-all {
  0% { transform: rotate(0deg) scale(0.65); }
  100% { transform: rotate(360deg) scale(0.65); }
}


@keyframes spin-top {
  0% { transform: rotate(-45deg); }
  100% { transform: rotate(315deg); }
}

@keyframes spin-bot {
  0%, 35% { transform: rotate(0deg); }
  80%, 100% { transform: rotate(-360deg); }
}

@keyframes spin-left {
  0%, 35% { transform: rotate(175deg); }
  80%, 100% { transform: rotate(535deg); }
}

@keyframes spin-last {
  0%, 10% { transform: rotate(-280deg); }
  90%, 100% { transform: rotate(-640deg); }
}

#bmc-wbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: rgb(255, 205, 75);
  color: white;
  border-radius: 32px;
  position: fixed;
  left: -5px!important;
  bottom: 100px!important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 8px;
  z-index: 9999;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.25s ease 0s;
  transform: scale(0.5)!important;
}
.p-dialog {
  z-index: 100000!important;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 90%;
  transform: scale(1);
  position: relative;
}
#tidio-chat-iframe{

  margin: 22px!important;
}
/*.swiper {
  width: 100%;
  height: 100%;
}*/
/*.swiper-slider {
  width: 600px;
  height: 300px;

}*/

/*.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

/*.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}*/

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

/*
.swiper-slide {
  background-size: cover;
  background-position: center;
}
*/

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

/*
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
}
*/
/*

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
*/

/*.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}*/
.icon {
  display: inline-block;
  width: 16.3px;
  height: 13.45px;
  overflow: hidden;
}
.icon > svg {
  position: relative;
}
.darkStar > svg {
  top: 0;
}
.lightStar > svg {
  top: -13.45px;
}
[_nghost-serverApp-c270] .cropper[_ngcontent-serverApp-c270] .resize[_ngcontent-serverApp-c270] .square[_ngcontent-serverApp-c270] {
  display: inline-block;
  background: #ef671e!important;
  width: 10px!important;
  height: 10px!important;
  border: 1px solid #ef671e!important;
  box-sizing: content-box;
}



#canvas { border: 1px solid black; }
.controls { margin-left: 5px; }
.split { display: flex; }
* { user-select: none; }

.resize-handle{
 /* width: 120px;
  height: 120px;
  border-radius: 8px;
  padding: 20px;
  margin: 1rem;
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif;

  touch-action: none;

  !* This makes things *much* easier *!
  box-sizing: border-box;*/
}

/*.resize-handle {
  border: 3px dashed rgba(0, 0, 0, 0.29);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.29);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100000 !important;

  min-height: 20px;
  min-width: 20px;
  background-color: #373839;
  border-radius: 0.75em;
}*/
/*
.drag-handle {
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  //border: 3px dashed rgba(0, 0, 0, 0.29);
  //box-shadow: 10px 5px 5px rgb(0 0 0 / 29%);
  display: block;
  //width: 97.1px;
  //margin: -14px;
  //padding: 1px;
  //color: white;
  //height: 31px;
  //font-size: 12px;
  //background-color: #111314;
  //border-radius: 0.55em;
  //opacity: 0.4;
}*/
#scale-element {
  display: block;
  max-width: 100%;
  margin: 1rem auto;
  touch-action: none;
}

#scale-element.reset {
  transition: transform 0.3s ease-in-out;
}
#canvas {
  touch-action: none;
  background-color: #eee;
  width: 100%;
  cursor: none;
  /* height: 100vw/1.77; */
}

#cursor {
  position: absolute;
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  opacity: 1;
  border: 34px solid;
  border-color: #fff;
  border-radius: 50%;
  pointer-events: none;
  transform: scale(0.4);
  transition: 0.15s transform;
}

#cursor.touched {
  transform: scale(1);
  transition: 0.15s transform;
}

#cursor::before {
  content: '';
  display: inline;
  width: 18px;
  height: 18px;
  border: 4px solid;
  border-color: #fff;
  opacity: 0.7;
  border-radius: 50%;
  transition: 0.15s transform border-width;
}

#cursor.touched::before {
  transform: scale(4.1);
  border-width: 7px;
  /* width: 62px; */
  /* height: 62px; */
  transition: 0.15s transform border-width;
}


.container {
  width: 90%;
  max-width: none;
}

#colorView {
  width: 100%;
  height: 150px;
  background-color: #3f51b5;
}

.card {
  margin: 0;
}

tr {
  border: none;
}

td {
  padding:5px;
  font-size: 1.2em;
  font-weight: bold;
}

td:first-child {
  color: #ccc;
  text-align: right;
  width: 30%;
}

.temptitle {
  opacity: 0.1;
  top: -2em;
  font-size: 0.3em;
}
.close-float-btn{
 @apply z-20 absolute top-0 left-0  click-animation text-[10px] p-1 bg-zinc-700 rounded-full  text-zinc-200;
}

.chessboard-bg {
  background-color:white;
  background-image: linear-gradient(45deg, #d0d0d0 25%, transparent 25%),
  linear-gradient(-45deg, #d0d0d0 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #d0d0d0 75%),
  linear-gradient(-45deg, transparent 75%, #d0d0d0 75%);
  background-size: 20px 20px; /* Размер плитки */
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.zoomist-container {
  width: 100%;
  //max-width: 600px;
}

.zoomist-image {
  width: 100%;
  aspect-ratio: 1;
}

.zoomist-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/*#container {
  touch-action: none; !* Для поддержки жестов *!
  overflow: hidden;
  transform-origin: center center;
}

.element {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}*/
/*@layer utilities {

}*/
.countdown {
  --duration: 60s;
  animation: countdown var(--duration) linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 440; /* Это значение должно быть равно длине окружности круга (2 * Math.PI * radius) */
  }
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}
#snake-container {
  margin-bottom: 20px;
}

#scroll-container {
  overflow: hidden; /* Убедитесь, что нет прокрутки, которая может добавить смещение */
}

#draw-canvas {
  display: block; /* Убедитесь, что canvas не имеет дополнительных отступов */
  background-color: white; /* Для видимости */
}

//canvas {
//  border: 1px solid black;
//  cursor: crosshair;
//}
body, html {
  margin: 0;
  padding: 0;
}
